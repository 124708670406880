<template>
  <LayoutSpSa>
    <section id="richiedi-reset">
      <h2>Reset password</h2>
      <hr v-if="isUserLoggedIn" />
      <div v-if="isUserLoggedIn" id="istruzioni">
        <div>
          Cliccando il pulsante in questa pagina si attiva il flusso di reset
          della password utilizzata per accedere alla web application.
          <br />
          Successivamente riceverete un'email con le istruzioni per procedere.
          <br />
          Le credenziali correnti continueranno a funzionare finché non sarà
          stata definita la nuova password in base alle suddette istruzioni.
        </div>
        <div id="info-reset-pw">
          <strong>Attenzione!</strong> Modificando la password di accesso, sarà
          necessario reinserire le credenziali per il Sistema Tessera Sanitaria,
          che verranno registrate nella base dati in un formato criptato
          indecifrabile dai nostri operatori
        </div>
      </div>
      <FormRichiediResetSpSa v-else />
      <hr v-if="isUserLoggedIn" />
      <button v-if="isUserLoggedIn" @click="richiediReset">
        Procedere al reset della password
      </button>
    </section>
  </LayoutSpSa>
</template>

<script>
import StatusCodes from "http-status-codes";
import { mapGetters } from "vuex";
import LayoutSpSa from "@/components/_layout/LayoutSpSa";
import FormRichiediResetSpSa from "@/components/LoginSpSa/FormRichiediResetSpSa";

const NAME = "RichiediResetSpSa";

export default {
  name: NAME,
  components: {
    LayoutSpSa,
    FormRichiediResetSpSa,
  },
  computed: {
    ...mapGetters({
      isUserLoggedIn: "auth/isUserLoggedIn",
      userData: "user/getData",
    }),
  },
  methods: {
    async richiediReset() {
      this.toggleLoader(true);
      let res = await this.$api.richiediResetPassword(
        this.userData.codiceFiscale
      );
      if (res.status !== StatusCodes.OK) {
        this.showError();
      } else {
        this.showSuccess();
      }
      this.toggleLoader(false);
      setTimeout(() => {
        location.href = location.pathname;
      }, 1000);
    },
  },
  notifications: {
    showSuccess: {
      title: "Richiesta accolta",
      message:
        "A breve riceverete un'email di istruzioni per procedere con l'operazione.",
      type: "success",
      html: null,
    },
    showError: {
      title: "Si è verificato un errore",
      message:
        "Provare di nuovo tra qualche minuto, se il problema persiste contattare l'assistenza.",
      type: "error",
      html: null,
    },
  },
};
</script>

<style src="@/styles/custom/views/_richiedi_reset.scss" lang="scss"></style>